@font-face {
  font-family: Font;
  src: url("/public/fonts/Yorkten-NorLig.ttf");
}

* {
  font-family: Font;
}
.details__header {
  display: flex;
  justify-content: space-around;
  gap: 5px;
  align-items: center;
  height: 60px;
  background-color: white;
  border-bottom: 2px solid #f4f4f4;
}

.header__text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1.1em;
}
.details__header {
  padding: 0.3em;
}
.details__btn {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 5px;
  z-index: 1;
}

.checklogo {
  display: flex;
  flex: 0.2;
}

.icon-container {
  display: none;
  background: #f4f4f4;
}

.hover-button {
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
}

.hover-button:hover + .icon-container {
  display: block;
  position: absolute;
  top: 60%;
  right: 260px;
  width: 10%;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-container:hover + .icon-container {
  display: block;
}

.mail-icon {
  color: #87ac8d;
}

.facebook-icon {
  color: #5e7ed6;
}

.share-icon {
  color: #87ac8d;
}

/*  */
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  background: #f0f0f0;
  padding: 5px;
}

.icons-section {
  display: flex;
  justify-content: space-between;
}

.dropdown:hover .dropbtn {
  /* background-color: #3e8e41; */
}
