.bn46 {
  width: 150px;
  height: 50px;
}

.bn45 {
  width: 150px;
  height: 50px;
}

.right-navbar {
  background-color: #87aa8d !important;
  color: white !important;
}

.right-navbar p {
  color: white !important;
}

.add-vehicle-btn1 {
  width: 13%;
  border-radius: 7px;
  background: #87aa8d;
  color: white;
  border: 1px solid white;
  font-size: 18px;
  cursor: pointer;
}

.add-vehicle-btn1 span {
  margin-left: 10px;
}

@media (max-width: 760px) {
  .add-vehicle-btn {
    margin-right: 60px !important;
  }

  .add-vehicle-btn1 {
    width: 50%;
  }
}
