 
    .css-hip9hq-MuiPaper-root-MuiAppBar-root {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: static;
        background-color: #42B94C;
        color: #fff;
    }

    .titleBar {
        position: relative;
        vertical-align: middle;
        text-align: center;
        padding:14px;
      }



      /* .nav-link {
        cursor:pointer;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: #031D44;
        padding: 20px 0px;
        margin: 0px 20px;
        display: inline-block;
        position: relative;
        opacity: 0.75;
      }
      
      .nav-link:hover {
        opacity: 1;
      }
      
      .nav-link::before {
        transition: 300ms;
        height: 5px;
        content: "";
        position: absolute;
        background-color: #031D44;
      }
      
      .nav-link-ltr::before {
        width: 0%;
        bottom: 10px;
      }
      
      .nav-link-ltr:hover::before {
        width: 100%;
      }
      
      .nav-link-fade-up::before {
        width: 100%;
        bottom: 5px;
        opacity: 0;
      }
      
      .nav-link-fade-up:hover::before {
        bottom: 10px;
        opacity: 1;
      }
      
      .nav-link-grow-up::before {
        height: 0%;
        width: 100%;
        bottom: 0px;
      }
      
      .nav-link-grow-up:hover::before {
        height: 5px;
      } */

      .hover-underline-animation {
        display: inline-block;
        position: relative;
        color: black;
      }
      
      .hover-underline-animation::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #87AC8D;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      
      .hover-underline-animation:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }