@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.card__container {
  position: relative;
  width: 100%;
  width: 230px;
  height: 350px;
  /* background-color: white; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 8px;
  font-family: "Poppins";
  cursor: pointer;
}

.css-i4l3dh {
  gap: 25px !important;
}

.car_name {
  text-align: center !important;
}

.car_name_title {
  font-size: 18px;
  font-weight: bold;
}

.car_name span {
  display: block !important;
  margin-bottom: 7px;
}

.car_adress {
  font-size: 12px;
  color: grey;
  /* margin:10px 0px; */
}

.car_name button {
  /* padding: 9px 29px !important; */
  padding: 9px 23px !important;
  border-radius: 22px !important;
  color: black !important;
  cursor: pointer;
}

.bookmark {
  width: 32px;
  height: 40px;
  background-color: #87ac8d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  right: 9px;
  top: 9px;
}

.btn__container {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.price__btn {
  background-color: #87ac8d;
  padding: 6px;
  border: none;
  font-size: 20px;
  color: white;
  font-weight: 400;
  border-radius: 4px;
}

.specs__container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 0.3em;
}

.model__text {
  color: #1f3823;
  font-size: 17px;
  font-weight: bold;
  margin-top: 0;
}

.car__img {
  width: 100%;
  object-fit: contain;
}

.details--container {
  /* padding: 8px; */
}

.image__container {
  position: relative;
}

.image__container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
}

.image__container:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.vin {
  padding: 0.5em;
  padding-inline: 1em;
  background: rgba(40, 38, 38, 0.2);
  position: absolute;
  left: 0px;
  bottom: 8px;
  color: white;
}

@media (max-width: 712px) {
  .card__container {
    width: 90%;
    height: 440px;
    margin-top: 10px;
  }
}
