@font-face {
  font-family: Font;
  src: url("/public/fonts/Yorkten-NorLig.ttf");
}

* {
  font-family: Font;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 0px !important;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-right-nav .image-gallery-svg {
  height: 30px !important;
  width: 30px !important;
  padding: 0px !important;
  color: #87ac8d !important;
  background-color: #f4f4f4;
  border-radius: 30px;
}
.image-gallery-left-nav .image-gallery-svg {
  height: 30px !important;
  width: 30px !important;
  padding: 0px !important;
  color: #87ac8d !important;
  background-color: #f4f4f4;
  border-radius: 30px;
}

/* .image-gallery-thumbnails {
    overflow: auto !important;
    padding: 5px 0 !important; 
    width: 700px !important;
}

.image-gallery-thumbnails::-webkit-scrollbar {
    display: none;
}
*/
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain !important;
}
.offer-modal {
  text-align: center;
  position: relative;
}

.close-icon {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  left: 24px !important;
  top: 35px !important;
  cursor: pointer;
}

.offer-modal img {
  width: 200px;
  height: 80px;
}

.profile-img {
  width: 90px !important;
  height: 90px !important;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-section span {
  font-weight: bolder;
}

.field-section {
  text-align: left !important;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.field-section .field {
  /* border: 1px solid black; */
  width: 70%;
  padding: 10px;
}

.field-section .field input {
  width: 90%;
  padding: 12px;
}

.field-section .field span {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 7px;
}

.offer-content p {
  font-weight: bolder;
  font-size: 18px;
}

.submitoffer-btn {
  width: 40% !important;
  background-color: gainsboro !important;
  background-color: #679567 !important;
  color: white !important;
  padding: 11px !important;
  border-radius: 7px !important;
  cursor: pointer !important;
}

.accordion-item {
  margin-bottom: 10px; /* Adjust spacing between accordion items */
}

.accordion {
  cursor: pointer;
  padding: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;

  background: white;
}

.last-item {
  border-bottom: 1px solid rgb(201, 201, 201);
}

.accordion:hover {
  background-color: white;
}

.accordion .arrow {
  font-size: 20px;
}

.accordion.active {
  background-color: white;
}

.panel {
  padding: 0 18px;
  overflow: hidden;
  background-color: white;
  display: none;
}

.panel p {
  margin: 0;
}

.active .panel {
  display: block;
}

.accordion-items-list {
  /* border: 1px solid grey; */
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.image-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-title-content img {
  width: 30px;
  height: 3%;
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.down-arrow,
.up-arrow {
  width: 20px;
  height: 20px;
}

@media (max-width: 1312px) {
  .image-gallery-slide .image-gallery-image {
    height: calc(100vh - 20px);
    object-fit: contain !important;
  }
}

@media (max-width: 1200px) {
  .field {
    width: 100% !important;
  }
}
