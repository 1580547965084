/*  
    .css-hip9hq-MuiPaper-root-MuiAppBar-root {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: static;
        background-color: #42B94C;
        color: #fff;
    } */
/* 
    .titleBar {
        position: relative;
        vertical-align: middle;
        text-align: center;
        padding:14px;
      } */
.css-hyum1k-MuiToolbar-root {
  min-height: 48px;
}

.css-h4y409-MuiList-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
}

.css-h4y409-MuiList-root::-webkit-scrollbar {
  width: 3px;
  background-color: white;
}

.css-h4y409-MuiList-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#87ac8d),
    to(#87ac8d),
    color-stop(0.6, #87ac8d)
  );
}

.css-19y3dt5-MuiModal-root-MuiDrawer-root
  .MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  background-color: white;
  border-radius: 8px;
}
.css-19y3dt5-MuiModal-root-MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar {
  width: 3px;
  background-color: white;
}
.css-19y3dt5-MuiModal-root-MuiDrawer-root
  .MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#87ac8d),
    to(#87ac8d),
    color-stop(0.6, #87ac8d)
  );
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px;
}

.responsive-navbar {
  display: none;
}

.navbar {
  display: none;
  position: fixed;
  width: 80%;
  height: 100%;
  z-index: 1;
  padding: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.navbar .drawer_parent_mobile {
  margin: 0;
  border: 0;
  overflow-y: scroll;
  height: 100%;
}
.navbar .drawer_parent_mobile > div > div {
  width: 100%;
  position: unset;
}
.navbar a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px;
}
.close {
  position: absolute;
  z-index: 2;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.coming-soon-btn {
  background-color: #fc0404;
  color: white;
  border-radius: 23px;
  /* width: 40%; */
  min-width: fit-content;
  border: none;
  padding: 7px 10px;
  font-size: 9px;
  max-lines: 1;
}

@media (max-width: 540px) {
  .responsive-navbar {
    position: absolute;
    right: 41px;
    top: 20px;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .navbar.show {
    display: block;
  }
}
