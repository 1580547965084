.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.specific-car-section {
  width: 100%;
  /* border: 1px solid blue; */
  margin: 20px;
  width: 95%;
  padding: 10px;
}

.specific-car-offer-img-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 10px;
  border-bottom: 1px solid #bab9b9;
}

.specific-car-offer-left-side-content {
  padding: 5px;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.specific-car-offer-left-side-content img {
  width: 220px;
  height: 170px;
}

.specific-car-offer-left-side-content h1 {
  font-weight: bolder;
}

.specific-car-section-1 {
  display: flex;
  width: 60%;
  /* border: 1px solid black; */
  margin: 10px;
  justify-content: space-between;
}

.specific-car-section-1-CONTENT {
  width: 20%;
}

.specific-card-btn-section {
  width: 100%;
  /* border: 1px solid black; */
  text-align: -webkit-right;
}

.specific-card-btn-section button {
  display: block;
  margin-bottom: 10px;
}

.archieve-btn {
  padding: 18px 29px;
  width: 15%;
  color: white;
  background: #ffd3db;
  border: none;
}

.accept-btn {
  padding: 18px 29px;
  width: 15%;
  color: white;
  background: rgb(107, 203, 107);
  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;

  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.App-link {
  color: #61dafb;
}
.MuiBox-root.css-1gsv261 {
  border: 0;
}
.drawer_parent > div > div {
  position: relative;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}
.input_fields fieldset {
  border: none;
  border-radius: 6px;
}
.filter_form > div > div {
  color: #969696 !important;
}
.top_div {
  position: sticky;
  top: 0;
  z-index: 10;
}
.drawer_parent {
  /* height: calc(100vh - 115px); */
  position: fixed;
  overflow-y: auto;
}
.drawer_parent::-webkit-scrollbar {
  display: none;
}
.disabled-link {
  pointer-events: none;
}
.offer-modal > div:nth-child(3) {
  border: none;
  max-height: 690px;
  height: 90%;
  overflow-y: auto;
}
.offer-modal > div:nth-child(3) > button {
  width: 150px !important;
}
.vehiclecard {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  gap: 20px !important;
}
.vehiclecard .vehiclecardinner {
  width: 100% !important;
  min-width: 100% !important;
}

.vehiclecard .vehiclecardinner div {
  box-shadow: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media (max-width: 1399px) {
  .header-right-side-content nav .nav-link {
    font-size: 16px;
  }
  .header-right-side-content .launch-btn {
    font-size: 16px;
  }
} */

@media (max-width: 1200px) {
  .specific-card-btn-section {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .specific-card-btn-section button {
    width: 50%;
  }

  .specific-car-offer-img-section {
    flex-direction: column;
    text-align: center;
  }

  .specific-car-offer-left-side-content {
    flex-direction: column;
    width: 100% !important;
  }

  .specific-car-section-1 {
    width: 100% !important;
  }

  .specific-car-section {
    margin: 0px;
    padding: 0px;
  }
  .vehiclecard {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
}

/* @media (min-width: 1200px) {
  .car_name button {
    padding: 9px 23px !important;
  }
} */
@media (max-width: 1024px) {
  .vehiclecard {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

@media (max-width: 991px) {
  .vehiclecard {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: 767px) {
  .vehiclecard {
    grid-template-columns: 1fr !important;
  }
}
